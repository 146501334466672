import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };

  return (
    <nav>
      <div className="logo">
        <Link to="/" onClick={handleLinkClick}>
          <img src="/logos/LogoHorizontal.png" alt="website logo" />
        </Link>
        {/* <Link className="logo-text" to="/" onClick={handleLinkClick}>
          <span className="logo-script">InnoNative</span>
          <span className="logo-solutions">Solutions</span>
        </Link> */}
      </div>
      <button
        className={`hamburger ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
        aria-label="Toggle navigation menu"
      >
        <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
      </button>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <li>
          <Link to="/pricing" onClick={handleLinkClick}>
            Pricing
          </Link>
        </li>
        <li>
          <Link to="/services" onClick={handleLinkClick}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={handleLinkClick}>
            About
          </Link>
        </li>
        <li>
          <Link to="/my-work" onClick={handleLinkClick}>
            Projects
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={handleLinkClick}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
